import React from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql, navigate } from 'gatsby';
import ProjectPreview from '../components/ProjectPreview';
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/portfolio.css'
import Draggable from "react-draggable"
import Video from '../components/Video';
import ShopPreview from '../components/ShopPreview';
import shirt from '../assets/placeholdershirt.png'
import '../styles/project.css'
import '../styles/feature-page.css'

import { renderRichText } from 'gatsby-source-contentful/rich-text'


const haveyouseenmycamera = ({ data }) => {
  const post = get(this, 'props.data.ContentfulProject')
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="black" />
        <title>have you seen my camera - Byrejjie</title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <MenuBar />
      <div className='feature-container'>
        <Draggable><h1 className='title feature-title'>have you seen my camera</h1></Draggable>
        <Video videoSrcURL="https://www.youtube-nocookie.com/embed/UsvFrOHz_MY" className="feature-video" />
        <br /> <br />
        <div className='feature-shop-contanier'>
          {
            data.allShopifyProduct.nodes.map(node => (
              <ShopPreview title={node.title} price={node.priceRangeV2.maxVariantPrice.amount} image={node.featuredImage.gatsbyImageData} url={node.handle} />
            ))
          }
        </div>
        <div className='haveyouseenmycamera-body'>{renderRichText(data.contentfulProject.body)}</div>
        <div className='project-gallery'>
          <div className='gallery-column'>
            {data.contentfulProject.galleryColumn1.map(gallery => (
              <GatsbyImage image={gallery.gatsbyImageData} className='project-gallery-image' />
            ))}
          </div>
          <div className='gallery-column'>
            {data.contentfulProject.galleryColumn2.map(gallery => (
              <GatsbyImage image={gallery.gatsbyImageData} className='project-gallery-image' />
            ))}
          </div>
          <div className='gallery-column'>
            {data.contentfulProject.galleryColumn3.map(gallery => (
              <GatsbyImage image={gallery.gatsbyImageData} className='project-gallery-image' />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export const query = graphql`
query ShirtQuery {
  allShopifyProduct(filter: {title: {eq: "have you seen my camera t-shirt"}}) {
    nodes {
      featuredImage {
        gatsbyImageData(width: 350)
      }
      title
      handle
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
      }
    }
  }
  contentfulProject(url: {eq: "haveyouseenmycamera"}) {
    videoEmbedUrl
    body {
      raw
    }
    galleryColumn1 {
      gatsbyImageData(quality: 85, width: 1080)
    }
    galleryColumn2 {
      gatsbyImageData(quality: 85, width: 1080)
    }
    galleryColumn3 {
      gatsbyImageData(quality: 85, width: 1080)
    }
  }
}
`

export default haveyouseenmycamera;